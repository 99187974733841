// Home.js
import React, { useState } from "react";
import { Typography, Box, Button, Grid, IconButton, Grid2, CardMedia, ButtonGroup, CardContent, Card } from "@mui/material/";
import "./Home.css";
import { AppBar, Toolbar, Container } from "@mui/material";
import CustomButton from "./CustonButton";
import { styled } from "@mui/system";
import backgroundVideo from "../../src/Art/pro.mp4";
import NavBar from "./navbar";
import LookingForTalent from "./LookingForTalent";
import CloseIcon from "@mui/icons-material/Close";
import FindMeJob from "./FindMeJob";
import HomeBackgroundVideo from "./HomeBackgroundVideo";

const StyledButton = styled(Button)({
    backgroundColor: "black",
    color: "white",
    "&:hover": {
        backgroundColor: "#333",
    },
});

function Home() {
    const [showFormOne, setShowFormOne] = useState(false);
    const [showFormTwo, setShowFormTwo] = useState(false);

    const handleLookingForTalentClick = () => {
        setShowFormOne(true);
    };

    const handleFindMeJob = () => {
        setShowFormTwo(true);
    };

    const handleCloseForm = () => {
        setShowFormOne(false);
        setShowFormTwo(false);
    };

    return (
        <div>
            <NavBar />
            <CardMedia component='video' image={backgroundVideo} autoPlay loop muted sx={{ filter: 'brightness(50%)' }} />
            <Grid2
                container
                spacing={2}
                sx={{
                    top: '0',
                    left: '0',
                    position: 'absolute',
                    width: '100%',
                    height: { xs: '25vh', sm: '29vh', md: '34vh', lg: '50vh', xl: '55vh' },
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "flex-end",
                    paddingBottom: '1rem',
                    // alignContent: { xs: 'flex-start', md: 'center' },
                    alignContent: 'center',
                    zIndex: '1',
                }}>
                <Grid2 container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Grid2 container spacing={{ xs: 1, md: 1, lg: 2, xl: 2 }} color='white' flexDirection='column' sx={{ ml: '2rem' }}>
                        <Grid2>
                            <Typography variant="h1" sx={{ fontSize: { xs: '15px', sm: '25px', md: '34px', lg: '50px' } }}>Where</Typography>
                        </Grid2>
                        <Grid2>
                            <Typography variant="h1" sx={{ fontSize: { xs: '15px', sm: '25px', md: '34px', lg: '50px' } }} > Experience</Typography>
                        </Grid2>
                        <Grid2>
                            <Typography variant="h1" sx={{ fontSize: { xs: '15px', sm: '25px', md: '34px', lg: '50px' } }} > Meets</Typography>
                        </Grid2>
                        <Grid2>
                            <Typography variant="h1" sx={{ fontSize: { xs: '15px', sm: '25px', md: '34px', lg: '50px' } }} > Opportunity<span style={{ color: "#EC4300" }}>.</span></Typography>
                        </Grid2>

                    </Grid2 >
                    <Grid2 sx={{ alignContent: 'center', }}>
                        <ButtonGroup orientation="vertical" size="large">
                            <Button variant="contained" sx={{ m: '2rem', backgroundColor: 'black', color: 'white', "&:hover": { backgroundColor: "#EC4300" }, fontSize: { xs: '0.5rem', sm: '1rem', md: '2rem', lg: '3rem' } }} onClick={handleLookingForTalentClick}>Looking For Talent</Button>
                            <Button variant="contained" sx={{ m: '2rem', backgroundColor: 'black', color: 'white', "&:hover": { backgroundColor: "#EC4300" }, fontSize: { xs: '0.5rem', sm: '1rem', md: '2rem', lg: '3rem' } }} onClick={handleFindMeJob}>Find Me A Job</Button>
                        </ButtonGroup>
                    </Grid2>
                    {showFormOne && (
                        <Box
                            sx={{
                                position: "fixed",
                                // top: "50%",
                                left: "50%",
                                transform: "translate(-50%, 10%)",
                                backgroundColor: "white",
                                padding: "2rem",
                                borderRadius: "8px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                maxWidth: "500px",
                                width: "100%",
                                zIndex: 1,
                            }}
                        >
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    top: "0.5rem",
                                    right: "0.5rem",
                                    color: "gray",
                                }}
                                onClick={handleCloseForm}
                            >
                                <CloseIcon />
                            </IconButton>
                            <LookingForTalent />
                        </Box>
                    )}
                    {
                        showFormTwo && (
                            <Box
                                sx={{
                                    position: "fixed",
                                    // top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, 10%)",
                                    backgroundColor: "white",
                                    padding: "2rem",
                                    borderRadius: "8px",
                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                    maxWidth: "500px",
                                    width: "100%",
                                    zIndex: 1,
                                }}
                            >
                                <IconButton
                                    sx={{
                                        position: "absolute",
                                        top: "0.5rem",
                                        right: "0.5rem",
                                        color: "gray",
                                    }}
                                    onClick={handleCloseForm}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <FindMeJob />
                            </Box>
                        )
                    }
                </Grid2 >
            </Grid2>
        </div >
    );
}

export default Home;
