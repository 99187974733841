import React, { useEffect, useRef } from "react";
import { Box, Typography, Grid2 } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";

function LegacyComponent() {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const boxVariants = {
        hidden: { opacity: 0, x: "-100%" },
        visible: { opacity: 1, x: 0, transition: { duration: 1.5 } },
    };

    const typographyVariants = {
        hidden: { opacity: 0, y: "100%" },
        visible: { opacity: 1, y: 0, transition: { duration: 2, delay: 0.5 } },
    };

    const { scrollYProgress } = useScroll();
    const scale = useTransform(scrollYProgress, [5, 5], [1, 2]);

    return (
        // <motion.div style={{ scale }}>
        // <motion.div style={{ scaleY: scrollYProgress }} >
        // <Box
        //     ref={ref}
        //     sx={{
        //         // height: "70vh",
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //         overflow: "hidden",
        //         padding: "4rem",
        //     }}
        // >
        //     <Box
        //         display="flex"
        //         flexDirection="row"
        //         color="#fff"
        //         height="100%"
        //         width="100%"
        //         p={0}
        //     >
        //         <motion.Box
        //             initial="hidden"
        //             animate={controls}
        //             variants={boxVariants}
        //             display="flex"
        //             alignItems="center"
        //             justifyContent="center"
        //             width="20%"
        //             bgcolor="transparent"
        //             color="#EC4300"
        //             m={-6}
        //             p={10}
        //         >
        //             <Typography
        //                 variant="h1"
        //                 color="white"
        //                 justifyContent="center"
        //                 alignContent="center"
        //                 fontFamily={"Alice"}
        //                 fontWeight={"normal"}
        //                 sx={{
        //                     writingMode: "vertical-rl",
        //                     padding: "6rem",
        //                     mt: 5,
        //                     transform: "rotate(180deg)",
        //                     fontSize: {
        //                         xs: "20px",
        //                         sm: "30px",
        //                         md: "40px",
        //                         lg: "45px",
        //                         xl: "50px",
        //                     },
        //                 }}
        //             >
        //                 Who we are ?
        //             </Typography>
        //         </motion.Box>
        //         <Box
        //             width="100%"
        //             bgcolor="white"
        //             color="#000"
        //             p={4}
        //             justifyContent="center"
        //             alignContent="center"
        //             fontFamily={"DM Sans"}
        //         >
        //             <motion.Typography
        //                 initial="hidden"
        //                 animate={controls}
        //                 variants={typographyVariants}
        //                 variant="h2"
        //                 style={{
        // fontSize: {
        //     xs: "20px",
        //     sm: "30px",
        //     md: "40px",
        //     lg: "45px",
        //     xl: "50px",
        // },
        //                 }}
        //             >
        //     With a legacy of over{ " " }
        // <span style={{ color: "#EC4300" }}>30+ Years</span> in
        //     Powering Industries with Top Talent, we have been
        //                     dedicated to Launching Successful Careers, Aligning
        //                     Leaders with Cutting - Edge Opportunities.
        //             </motion.Typography>
        //         </Box>
        //     </Box>
        // </Box>
        // </motion.div>
        // </motion.div>
        < div >
            <Grid2 container spacing={2} padding={2} sx={{ height: '70vh' }} >
                <Grid2 sx={{ backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} size="grow">
                    <Typography variant="h2" sx={{ writingMode: 'vertical-rl', rotate: '180deg', }} fontSize={{ xs: "25px", sm: "30px", md: "38px", lg: "45px", xl: "70px" }}>Who we are?</Typography>
                </Grid2>
                <Grid2 size={10} sx={{ backgroundColor: 'white', color: 'black', justifyContent: 'center', alignItems: "center", alignContent: 'center', }}>
                    <Typography variant="body1" padding={2} fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "30px", xl: "35px" }}>
                        With a legacy of over{" "}
                        <span style={{ color: "#EC4300" }}>30+ Years</span> in
                        Powering Industries with Top Talent, we have been
                        dedicated to Launching Successful Careers, Aligning
                        Leaders with Cutting-Edge Opportunities.
                    </Typography>
                </Grid2>
            </Grid2>
        </div >
    );
}

export default LegacyComponent;
