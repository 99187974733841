import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./navbar.css";
import { Card, CardActionArea, CardMedia, Grid2, IconButton } from "@mui/material";
import Menu from "./Menu";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../src/Art/logo3.png";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

export default function NavBar() {
    let [showMenu, setShowMenu] = React.useState(false);
    let navigate = useNavigate();
    const navItems = ["Home", "About", "Services", "Portfolio", "Contact"];

    let handleShowMenu = () => {
        showMenu ? setShowMenu(false) : setShowMenu(true);
    }

    let handleHomeClick = () => {
        navigate("/");
    };

    const handleAboutClick = () => {
        navigate("/about-us");
    };

    const handlePortfolioClick = () => {
        navigate("/portfolio");
    };

    const handleServicesClick = () => {
        navigate("/services");
    };

    const handleContactClick = () => {
        navigate("/contact");
    };

    return (
        <AppBar
            position="fixed"
            sx={{ backgroundColor: "transparent" }}
            elevation={0}
        >
            {/* <AppBar position="static" color='transparent' elevation={0}> */}
            <Toolbar>
                <Grid2 container spacing={2} padding='1rem'>
                    <Grid2 size={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Card
                            elevation={0}
                            sx={{
                                backgroundColor: "transparent", // for transparency
                            }}
                        >
                            <CardActionArea
                                onClick={() => {
                                    navigate("/");
                                }}
                                sx={{
                                    // padding: {
                                    //     xs: "0.1rem",
                                    //     sm: "0.2rem",
                                    //     md: "0.3rem",
                                    //     lg: "0.4rem",
                                    //     xl: "0.5rem",
                                    // },
                                    "&:hover": {
                                        backgroundColor: "#00000055",
                                        color: "white",
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    src={logo}
                                    sx={{
                                        backgroundSize: "contain",
                                        backgroundColor: "transparent",
                                    }}
                                    elevation={0}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid2>
                    <Grid2 container spacing={0} size='grow' sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                        <Grid2>
                            <Button
                                onClick={handleHomeClick}
                                sx={{
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#EC4300" },
                                }}
                            >
                                Home
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button
                                onClick={handleAboutClick}
                                sx={{
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#EC4300" },
                                }}
                            >
                                About
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button
                                onClick={handlePortfolioClick}
                                sx={{
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#EC4300" },
                                }}
                            >
                                Portfolio
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button
                                onClick={handleServicesClick}
                                sx={{
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#EC4300" },
                                }}
                            >
                                Industry Focus
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button
                                onClick={handleContactClick}
                                sx={{
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#EC4300" },
                                }}
                            >
                                Contact
                            </Button>
                        </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2} size='grow' sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', alignContent: 'flex-start' }}>
                        <IconButton onClick={() => { handleShowMenu() }}>
                            <MenuRoundedIcon color="warning" fontSize="large" />
                        </IconButton>
                    </Grid2>
                    {showMenu &&
                        <Grid2 container spacing={0} sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                            <Grid2>
                                <Button
                                    onClick={handleHomeClick}
                                    sx={{
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#EC4300" },
                                    }}
                                >
                                    Home
                                </Button>
                            </Grid2>
                            <Grid2>
                                <Button
                                    onClick={handleAboutClick}
                                    sx={{
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#EC4300" },
                                    }}
                                >
                                    About
                                </Button>
                            </Grid2>
                            <Grid2>
                                <Button
                                    onClick={handlePortfolioClick}
                                    sx={{
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#EC4300" },
                                    }}
                                >
                                    Portfolio
                                </Button>
                            </Grid2>
                            <Grid2>
                                <Button
                                    onClick={handleServicesClick}
                                    sx={{
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#EC4300" },
                                    }}
                                >
                                    Industry Focus
                                </Button>
                            </Grid2>
                            <Grid2>
                                <Button
                                    onClick={handleContactClick}
                                    sx={{
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#EC4300" },
                                    }}
                                >
                                    Contact
                                </Button>
                            </Grid2>
                        </Grid2>
                    }
                </Grid2>
            </Toolbar>
        </AppBar>
    );
}
