import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    Grid,
    Typography,
    Button,
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid2,
} from "@mui/material";
import "./About.css";
import { Zoom } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Achievements from "./Achievements";
import image from "../../src/Art/simplebg.jpg";
import bgnew from "../../src/Art/aboutbgnew1.jpg";
import backgroundVideo from "../../src/Art/bgabout1.mp4";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        minHeight: "70vh",
        overflow: "hidden",
    },
    image: {
        width: "100%",
        height: "auto",
        borderRadius: 1,
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        transition: "transform 5s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
        },
    },
    button: {
        marginTop: 2,
    },
    videoBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "70vh",
        overflow: "hidden",
        zIndex: 0,
        "& video": {
            minWidth: "100%",
            minHeight: "100%",
            width: "100%",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    },
}));

const About = () => {
    let navigate = useNavigate();
    const classes = useStyles();
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const handleLearnMoreClick = () => {
        navigate("/about-us");
    };

    return (
        <Box sx={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
            <Box
                component="img"
                src={bgnew}
                alt="background-image"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: 'brightness(75%)',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '15%',
                    left: '10%',
                    transform: 'translateY(-50%)',
                    width: { xs: '80%', sm: '60%', md: '50%' },
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        color: 'white',
                        textShadow: '2px 2px 5px rgba(0,0,0,0.5)',
                        fontWeight: 'bolder',
                        fontSize: { xs: '2rem', sm: '3rem', md: '5rem' },
                    }}
                >
                    About Us
                </Typography>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '45%',
                    transform: 'translateY(-50%)',
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        color: 'white',
                        textShadow: '2px 2px 5px rgba(0,0,0,0.5)',
                        fontSize: { xs: '0.75rem', sm: '1.5rem', md: '2rem' },
                    }}
                >
                    Phoenix Consultant stands as a premier provider of strategic recruitment
                    solutions, with a legacy of excellence spanning over two decades. Established in{' '}
                    <span style={{ color: '#EC4300', fontWeight: 'bold' }}>1992</span>, we have
                    earned our reputation as a trusted leader in the recruitment industry,
                    dedicated to connecting top-tier talent with leading organizations.
                </Typography>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '60%',
                    left: '45%',
                    transform: 'translateY(-50%)',
                }}
            >
                <Button
                    variant="contained"
                    className={classes.button}
                    elevation={20}
                    sx={{
                        color: '#000',
                        fontWeight: 'bold',
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: '#EC4300',
                            color: 'white',
                        },
                        padding: { xs: '0.35rem 0.75rem', sm: '0.75rem 1.5rem' },
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                    }}
                    onClick={handleLearnMoreClick}
                >
                    Learn More
                </Button>
            </Box>
        </Box>
    );
};

export default About;
